// Body
$body-bg: #f5f8fa;

// Typography
$font-family-sans-serif: 'Pt Sans', sans-serif;
$font-size-base: 0.8rem;
$line-height-base: 1.6;


$btn-padding-x: 10px;
$btn-padding-y: 4px;

//$success = #1dc987;

$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 1192px,
        xl: 1320px
) !default;

$grid-gutter-width: 30px;

// Ваши переназначения
$grid-breakpoints: (
        xs: 0,
        sm: 776px,
        md: 968px,
        lg: 1192px,
        xl: 1400px
);
$metal: #7f8e9b;
$primary: #6079fe;
$secondary: #e9e9f2;
$success: #50cd89;
$warning: #ffc107;
$danger: #F64E60;
$light: #efefef;
$info: #3699FF;

$input-color: #495057;

$black: #000000;
$white: #ffffff;


$badge-border-radius: 0.35rem;
$badge-padding-x:  0.55em;
$badge-padding-Y:  0.7em;
$badge-font-size: 0.8em;
$badge-font-weight: 400;

$font-size-sm: 0.8rem;
$input-border-color: #eeeeee;
$input-bg: $light;
$input-font-size: 0.9rem;

*.danger{background: $danger !important;}
*.info{background: $info !important;}
*.warning{background: $warning !important;}
*.success{background: $success !important;}

$border-color: #eeeeee;
@charset "UTF-8";

body {
    position: relative;
    font-size: 13px;
    font-family: 'Pt Sans', sans-serif;
    color: #595D6D;
    background: #efeff5;
    margin: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: bold;
}

a {
    transition: all .3s ease;
}

.logo {
    background: url('../images/cloud_tg-primary.svg') no-repeat center;
    background-size: 120px 40px;
    width: 120px;
    height: 60px;
    margin-left: 20px;
}

.logo.white {
    background-image: url('../images/cloudtg_white.svg');
}


.motion-sidebar .sidebar-sticky:not(:hover) .logo {
    width: 40px;
    height: 60px;
    background-size: 120px 40px;
    background-position: left;
    margin-left: 10px;
}

.sidebar-sticky .toggle-nav {
    font-size: 20px;
    color: #5f79fe;
    font-weight: 100;
    margin-right: 25px;
    cursor: pointer;
}

.motion-sidebar .sidebar-sticky:not(:hover) .toggle-nav {
    display: none;
}

/*all*/
*.danger {
    color: #6f082a !important;
    background: #ff8eb4 !important;
}

*.warning {
    color: #4a3302 !important;
    background: #ffb822 !important;
}

*.info {
    background: #5da6ff !important;
    color: #0f1a46 !important;
}

*.success {
    background: #1dc9b7 !important;
    color: #006157 !important;
}

*.metal {
    background-color: rgba(137, 141, 148, 0.49) !important;
    color: #545454 !important;
}

*.dark {
    background: rgba(34, 34, 77, 0.1) !important;
    color: #645ca1 !important;
}

*.danger-solid {
    background: rgba(253, 57, 122, 0.1) !important;
    color: #fd397a !important;
}

*.warning-solid {
    background: rgba(255, 184, 34, 0.1) !important;
    color: #ffb822 !important;
}

*.info-solid {
    background: rgba(93, 186, 255, 0.1) !important;
    color: #5da6ff !important;
}

*.success-solid {
    background: rgba(29, 201, 183, 0.1) !important;
    color: #1dc9b7 !important;
}

*.metal-solid {
    background-color: rgba(211, 218, 230, 0.49) !important;
    color: #545454 !important;
}

*.dark-solid {
    background: rgba(100, 92, 161, 0.1) !important;
    color: #645ca1 !important;
}

.alert.alert-primary {
    color: #fff;
    background-color: #5c78ff;
    border-color: #5c78ff;
}

.alert.alert-danger {
    background: rgba(253, 57, 122, 0.1);
    border-color: transparent;
    color: #fd397a;
}

.bg-primary {
    background-color: #617bff !important;
}

/*text*/
*.text-danger {
    color: #d8404e;
}

*.text-warning {
    color: #ffb822;
}

*.text-info {
    color: #289eeb;
}

*.text-success {
    color: #1dc987;
}

*.text-metal {
    color: #d3dae6;
}

.text-center {
    text-align: center;
}

.btn .svg-inline--fa {
    vertical-align: middle;
}

.btn.btn-icon .svg-inline--fa {
    margin: 0;
}

.btn:not(.btn-no-shadow):not(.btn-link) {
    position: relative;
    display: inline-block;
    z-index: auto;
}

.btn-link {
    padding: 0 5px;
}

.btn-loading {
    border: none;
    pointer-events: none;
    background: transparent;
    color: transparent;
    height: 40px;
    transition: none !important;
}

.btn {
    padding: 4px 10px;
}

.btn:not(.btn-outline-*) {
    border-color: transparent;
}

.btn:not(.btn-fill):not(:hover) {
    background-color: rgba(93, 120, 255, 0.1);
    color: inherit;
}

.btn.btn-success:not(.btn-fill) .svg-inline--fa {
    color: #1dc987;
}

.btn.btn-danger:not(.btn-fill) .svg-inline--fa {
    color: #c82333;
}

.btn.btn-warning:not(.btn-fill) .svg-inline--fa {
    color: #e0a800;
}

.btn.btn-info:not(.btn-fill) .svg-inline--fa {
    color: #157cbf;
}

.btn:not(.btn-fill):not(.btn-icon):hover .svg-inline--fa {
    color: inherit;
}

.btn.btn-icon {
    border: none;
    opacity: 0.8;
}

.btn.btn-icon:hover {
    color: inherit;
    background: transparent;
    opacity: 1;
}

.btn.btn-icon .svg-inline--fa {
    margin: 0;
}

.btn-loading:before {
    opacity: 0;
}

.btn-loading:after {
    right: auto;
    left: auto;
}

.btn-info {
    background-color: #289eeb;
}

.btn-info:hover {
    background-color: #157cbf;
}

.btn:not(.btn-no-shadow):not(.btn-link):before {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom: 6px solid;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: all .3s ease;
    display: none;
}

.btn:not(.btn-no-shadow):not(.btn-link):hover:before {
    color: transparent;
}

.btn:not(.btn-no-shadow):not(.btn-link):after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 2px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1;
    background: inherit;
    height: 5px;
    display: none;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn:after {
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle):before, .btn-group > .btn-group:not(:last-child) > .btn:before {
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child):not(.dropdown-toggle):after, .btn-group > .btn-group:not(:last-child) > .btn:after {
    border-bottom-left-radius: 0;
}

.btn-group > .btn:not(:first-child):not(.dropdown-toggle):before, .btn-group > .btn-group:not(:last-child) > .btn:before {
    border-bottom-left-radius: 0;
}

.loading {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.loading.preloader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
}

.loading:before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.91);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.loading:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    border: 1px solid #e1e6e887;
    border-radius: 100%;
    border-left-color: #FF5722;
    animation: rotate-preloader 1s linear 0s infinite normal;
    z-index: 11;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
}

@-webkit-keyframes rotate-preloader {
    50% {
        border-left-color: #E91E63;
    }

    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-preloader {
    50% {
        border-left-color: #E91E63;
    }

    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.main {
    /* position: fixed; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 0;
    margin: 0;
}

.main.motion-sidebar .sidebar-nav {
    margin: 15px 0;
}

.main .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0px;
    width: 250px;
    background: #242939;
    overflow: auto;
    z-index: 100;
    color: #fff;
    padding-bottom: 80px;
}

.mobile-services {

}

.mobile-services .btn {
    opacity: 0.5;
    color: inherit;
    background-color: rgba(93, 120, 255, 0.1);
}

.mobile-services .active {
    opacity: 1;
}

.main.motion-sidebar .sidebar {
    width: 60px;
    overflow-x: hidden;
    transition: all .3s ease;
}

.main.motion-sidebar .sidebar-footer {
    transform: translateY(100%)
}

.main.motion-sidebar .sidebar:hover .sidebar-footer {
    transform: none;
}

.main.motion-sidebar .sidebar:hover {
    width: 250px;
    overflow-x: auto;
}

.main .main-container {
    margin-left: 250px;
    padding: 60px 0 45px 0;
    position: relative;
    /* overflow: hidden; */
    min-height: 720px;
}

.main.pressed .main-container {
    margin-left: 250px;
}

.main.motion-sidebar .main-container {
    margin-left: 60px;
}

.sidebar-sticky {
}

.sidebar-sticky .vdpHeader {
    background: #1f2234;
}

.sidebar-sticky .vdpCell.outOfRange {
    color: #364a8b
}

.vdpCell {
    padding: .5em 0 !important;
}

.motion-sidebar .sidebar-sticky:not(:hover) .vdpComponent {
    display: none;
}

.sidebar-sticky .vdpComponent {
    color: #aaa;
}

.sidebar-sticky .vdpPeriodControl button {
    color: #aaa;
}

.sidebar-sticky .vdpInnerWrap {
    background: #1f2433;

}

.sidebar-sticky #user {
}

.sidebar-sticky .toggle-sidebar {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 120%;
    padding: 0;
    margin: 0;
    opacity: 0.5;
}

.user-bar {
    /* padding: 12px; */
}

.user-menu {
    background: #242628;
}

.user-menu .menu-item {
    width: 100%;
    display: inline-block;
    padding: 8px 20px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.user-menu .menu-item .svg-inline--fa {
    opacity: 0.4;
    width: 25px;
    text-align: left;
    margin-right: 10px;
}

.user-menu .menu-item:hover {
    background: #0488f7;
}

.user-bar .avatar {
    background-color: #5d78ff;
    width: 45px;
    height: 45px;
}

.user-bar .name {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 110%;
    color: #5d77ff;
    font-weight: bold;
    letter-spacing: 0.3pt;
}

.user-bar .name:after {
    width: 5px;
    height: 5px;
    border: 2px solid transparent;
    border-bottom-color: white;
    border-right-color: white;
    transform: rotate(45deg);
    transition: all .3s ease;
    top: calc(50% - 3px);
    transform-origin: center;
    right: -25px;
}

.user-bar .name.opened:after {
    transform: rotate(-135deg);
}

.sidebar-sticky .messages {
    position: relative;
    overflow: hidden;
}

.sidebar-sticky .user-bar .messages:hover {
    background: #1f2433;
}

.sidebar-sticky .user-bar .messages .svg-inline--fa {
}

.sidebar-sticky .sidebar-footer .badge {
    background: #ffffff;
    border-radius: 100%;
    position: absolute;
    top: calc(50% - 15px);
    left: 50%;
    display: flex;
    font-size: 12px;
}

.sidebar-sticky .avatar {
    width: 36px;
    height: 36px;
    display: inline-flex;
    background: #e5e5e5;
    color: #888;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 16pt;
    font-weight: bold;
    border-radius: 100%;
}

.sidebar-footer {
    bottom: 0;
    position: fixed;
    width: 250px;
    background: #1F2234;
    display: flex;
    justify-content: space-around;
    padding: 0 25px;
    align-items: center;
    height: 60px;
    z-index: 10;
    transition: all .3s ease-in-out;
    transform-origin: bottom;
}

.sidebar-footer .item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.sidebar-footer .item button {
    background: transparent;
    border: none;
    color: #364b8b;
    font-size: 18px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
}

.sidebar-footer .item button:hover {
    background: #191b27;
    color: #5d77ff;
}

.sidebar-footer .item button .svg-inline--fa {
}

.sidebar-nav {
    margin: 10px;
    padding-bottom: 40px;
}

.sidebar-nav .nav-item {
    text-align: center;
    overflow: hidden;
    position: relative;
}

.sidebar-nav .nav-item > a {
    /* border-left: 2px solid transparent; */
}

.sidebar-nav .nav-item .nav-link {
    padding: 12px 15px;
    font-size: 100%;
    color: inherit;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    z-index: 10;
    /* background: #323437; */
}

.sidebar-nav .nav-item .nav-link .title {
    color: #fff;
    opacity: 0.7;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-right: 20px;
    font-weight: 500;
    transition: all .3s ease;
}

.sidebar-nav .nav-item .nav-link .title:after {
    content: '+';
    position: absolute;
    right: 0;
    font-size: 16px;
    transform-origin: center;
    transition: all .3s ease-in-out;
}

.main.motion-sidebar .sidebar-nav .nav-item .nav-link .title .name {
    font-size: 0;
}

.main.motion-sidebar .sidebar-nav .nav-item .nav-btn {
    pointer-events: none;
    opacity: 0;
}

.sidebar-nav .nav-item .nav-link .title .badges {
    display: inline-flex;
    align-items: center;
}

.main.motion-sidebar .sidebar-nav .nav-item .nav-link .title .badges {
    position: absolute;
    right: 15px;
    top: 3px;
}

.main.motion-sidebar .sidebar:hover .sidebar-nav .nav-item .nav-link .title .badge {
    position: static;
}

.main.motion-sidebar .sidebar:hover .sidebar-nav .nav-item .nav-link .title .name {
    font-size: 100%;
}

.main.motion-sidebar .sidebar:hover .sidebar-nav .nav-item .nav-btn {
    pointer-events: all;
    opacity: 1;
}

.sidebar-nav .nav-item > .nav-link[aria-expanded="true"] {
    border-left-color: inherit;
    background: #5787ec1a;
    border-radius: 25px;
}

.sidebar-nav .nav-item > .nav-link:hover .title,
.sidebar-nav .nav-item > .nav-link[aria-expanded="true"] .title {
    color: #fff;
    opacity: 1;
}

.sidebar-nav .nav-item > .nav-link .svg-inline--fa {
    transition: all .3s ease
}

.sidebar-nav .nav-item > .nav-link:not([aria-expanded="true"]):not(:hover) .svg-inline--fa {
    opacity: 0.4;
    color: #687393;
}

.sidebar-nav .nav-item > .nav-link[aria-expanded="true"] .title:after {
    transform: rotate(45deg);
}

.sidebar-nav .nav-link.active {
    color: inherit !important;
    background: #1f2433;
}

.sidebar-nav .svg-inline--fa {
    margin-right: 8px;
    width: 25px;
    color: inherit;
    font-size: 16px;
}

.main.motion-sidebar .sidebar-nav .sub-nav {
    transition: all .2s ease;
    transform-origin: top;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.main.motion-sidebar .sidebar:hover .sidebar-nav .sub-nav {
    transform: translateY(0);
    position: relative;
    z-index: 1;
    opacity: 1;
}

.sidebar-nav .sub-nav {
    /* border-left: 2px solid; */
    position: relative;
    z-index: 0;
}

.sidebar-nav .sub-nav a.nav-link {
    color: #959da2;
    justify-content: space-between;
    position: relative;
    height: 40px;
    padding-left: 35px;
    border-radius: 25px;
}

.sidebar-nav .sub-nav a.nav-link:before {
    content: '';
    /* color: transparent; */
    position: absolute;
    border: 3px solid;
    border-radius: 100%;
    margin-left: -20px;
    top: calc(50% - 3px);
}

.sidebar-nav .sub-nav a.nav-link.active:before {
    color: inherit;
}

.sidebar-nav .sub-nav a.nav-link:hover {
    color: #fff;
}

.sidebar-nav .sub-nav li {
    width: 100%;
    text-align: left;
    margin: 0 15px;
}

.sidebar-nav .sub-nav li:first-child {
    margin-top: 8px;
}

.sidebar-nav .sub-nav li:last-child {
    margin-bottom: 15px;
}

.sidebar-nav .storage {
    color: var(--success);
}

.sidebar-nav .orders {
    color: #d74f65;
}

.sidebar-nav .contacts {
    color: #b9bfd5;
}

.sidebar-nav .settings {
    color: #979797;
}

.sidebar-nav .mediafiles {
    color: #58b7f9;
}

.sidebar-nav .kassa {
    color: #f2a260;
}

.sidebar-nav .payments {
    color: #e7c15c;
}

.sidebar-nav .docs {
    color: #3d8ece;
}

.fa-money-bill {
    color: #f1cc30;
}

/*feed-of-items*/
.feed-of-items {
    margin: 0;
    overflow: auto;
}

.feed-of-items.actions {
    padding-left: 30px;
}

.feed-of-items .feed {
    margin: 0 0 60px 0;
    /* background: #e8ebf1; */
}

.feed-of-items .feed .head {
    padding: 8px 15px;
    /* border-bottom: 5px solid #aaa; */
}

.feed-of-items .feed .head .title {
    font-weight: bold;
    text-transform: uppercase;
}

.feed-of-items .feed .head .count {
}

.feed-list-header {
    padding: 0 15px;
    font-weight: 100;
    font-size: 90%;
    background: #ffffff;
    color: #888;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: default;
    border: 1px solid #d9e3ea;
    margin-bottom: -1px;
}

.feed-list-footer {
    background: #e8ebf1;
    font-weight: bold;
    color: #7f8e9b;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.feed-list-footer .item {
    padding: 10px 15px 10px 15px;
}

.feed-list-body {
    position: relative;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #d9e3ea;
}

.feed-of-items.scrollable .feed-list-body {
    overflow: auto;
}
.table th, .table td{
    padding: 0.55rem 0.75rem;
}

.feed-list-body .item {
    padding: 0 15px;
    background: #fff;
    color: inherit;
    border-bottom: 1px solid #d9e3ea;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
}

.feed-list-body .item > .row {
}

.feed-list-header > .row > div {
    padding-top: 6px;
    padding-bottom: 6px;
}

.feed-list-body .item > .row > div {
    padding-top: 1px;
    padding-bottom: 1px;
}

.bordered .feed-list-body .item > .row > div:not(:last-child),
.bordered .feed-list-header > .row > div:not(:last-child) {
    border-right: 1px solid #d9e3ea
}

.feed-list-header .row > div:not(.dropdown),
.feed-list-body .item .row + div:not(.dropdown) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feed-list-body .item .row div.white-space {
    overflow: inherit;
    text-overflow: unset;
    white-space: normal;
}

.feed-list-body .item .chat {
    font-size: 12px;
    padding: 12px !important;
}

.feed-list-body .item .chat .message {
    padding: 8px 15px;
    border-radius: 15px;
    line-height: 1.1;
}

.feed-list-body .item .chat .message .author {
    display: block;
    font-size: 10px;
}

.feed-list-body .item .chat .message .text {
}

.feed-list-body .item.add-new {
    color: #111;
    opacity: 0.6;
}

.feed-list-body .item.add-new:hover,
.feed-list-body .item.add-new:focus {
    opacity: 1;
}

.feed-list-body .item .amount {
    font-size: 110%
}

.feed-list-body .item .amount.danger {
    color: var(--danger);
}

.feed-list-body .item .amount.warning {
    color: var(--warning);
}

.feed-list-body .item .amount.success {
    color: var(--success);
}

.feed-list-body .item:last-child {
    border-bottom: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.feed-list-body .item.success {
    background: #58c06a3d;
    /* outline: #90e29f solid 2px; */
    z-index: 1;
}

.feed-list-body .item.danger {
    background: rgba(255, 193, 6, 0.19);
}

.feed-list-body .item:hover {
    background: rgb(239, 239, 239) !important;
}

.feed-list-body .item.bad {
    background: rgba(197, 83, 98, 0.16);
}

.feed-list-body .item .name {
    font-weight: bold;
    color: inherit;
}

.feed-list-body .item:nth-child(2n) {
    /* background:#f9f9f9; */
}

.feed-list-body .items-list {
    background: #e7ecf0;
    padding: 0px 15px 50px 78px;
    border-top: 2px solid var(--info);
}

.feed-list-body .items-list.danger {
    background: #f7e0e0;
    border-top-color: var(--danger);
    color: inherit;
}

.feed-list-body .items-list .items-list-head {
    padding: 12px 15px;
    font-weight: bold;
    font-size: 10pt;
}

.feed-list-body .items-list.no-padding .items-list-head {
    padding: 12px 0;
}

.feed-list-body .items-list .items-list-body {
    padding-bottom: 15px;
}

.feed-list-body .items-list .items-list-body .item { /* border: none; */
    padding-left: 15px !important;
}

/*page-header*/
.page-header {
    position: relative;
    padding: 15px 0;
}

.page-header .actions {
    /* text-align: right; */
}

.page-header .header-icon {
    font-size: 20pt;
    width: 30px;
    margin-right: 8px;
}

.page-header .title {
    font-size: 1.2rem;
    /* margin: 0 10px 0 0; */
    color: #96969c;
    /* padding: 20px 8px 20px 25px; */
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0;
}

.page-after-header {
    margin: -0px 0 20px 0;
}

/*.storage-scheme{}*/
.storage-scheme {
    margin: 30px 0;
}

.storage-scheme .store-item {
    text-align: center;
    padding: 8px;
    background: #eee;
    margin-bottom: 15px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}

.storage-scheme .store-list .store-item:before {
    content: '';
    position: absolute;
    width: 15px;
    border-bottom: 2px solid #bbbbbb;

    left: -15px;
    top: calc(50% - 1px);
    z-index: 10;
}

.storage-scheme .store-item[aria-expanded="true"]:after {
    content: '';
    position: absolute;
    width: 15px;
    border-bottom: 2px solid #bbbbbb;
    right: -15px;
    top: calc(50% - 1px);
    z-index: 10;
}

.storage-scheme .store-item[aria-expanded="true"] {
    background: #4070b7;
    color: #fff;
}

.storage-scheme .store-list {
    position: relative;
}

.storage-scheme .store-list:before {
    content: '';
    position: absolute;
    border-left: 2px solid #bbb;
    top: 20px;
    bottom: 20px;
    left: -15px;
}

.storage-scheme .store-header {
    text-align: center;
    margin-bottom: 15px;
    opacity: 0.4;
    font-weight: bold;
    font-size: 80%;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-bar {
    height: 60px;
    background: #fff;
    padding: 0 25px;
    left: 250px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    border-bottom: 1px solid #f3f5f5;
}

.top-services {
    height: 100%;
    display: inline-flex;
}

.top-services .btn {
    height: 60px;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 15px;
}

.top-services .btn:not(.btn-fill):not(:hover) {
    background-color: transparent;
}

.top-services .btn:hover {
}

.top-services .btn.active {
    border-bottom-color: #5f79fe;
    background: #3f51b512 !important;
}

.main.motion-sidebar .top-bar {
    left: 60px;
}

[data-toggle="dropdown"] {
    cursor: pointer;
}

.top-bar {
}

.top-bar .tools {
}

.top-bar .tools .item button {
    width: 40px;
    height: 40px;
    text-align: center;
}

.top-bar .tools .item button .badge {
    background: #5c78ff;
    border-radius: 15px;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    padding: 3px 5px 2px 5px;
    position: absolute;
}

.top-bar .user {
    margin-right: 15px;
}

.top-bar-btn {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    color: #555;
    border: none;
    cursor: pointer;
    transition: all .3s ease-out
}

.top-bar-btn:hover {
    color: #fff;
    background: #42c075;
}

.status-label {
    position: relative;
    padding: 5px 8px 5px 15px;
    background: #7f8e9b;
    color: #fff;
    margin: 0 15px;
    white-space: nowrap;
    border-radius: 3px;
    height: 24px;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 9px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    letter-spacing: 1px;
}

.status-label[aria-expanded=true] .arrow {
    transform: rotate(180deg);
}

.status-label .arrow {
    border: 5px solid transparent;
    border-bottom: none;
    border-top-color: inherit;
    position: relative;
    margin: 0px 0 0 10px;
    transform-origin: center;
}

.status-label .dropdown-menu {
    text-transform: none;
    box-shadow: 0 3px 5px rgba(36, 45, 50, 0.27);
    color: #646c9a !important;
}

.status-label:before,
.status-label:after {
    content: '';
    position: absolute;
}

.status-label:before {
    height: 18px;
    width: 18px;
    background: inherit;
    left: -7px;
    top: 3px;
    border-radius: 3px;
    transform-origin: center;
    transform: rotate(225deg);
}

.status-label:after {
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 100%;
    left: 0;
    top: calc(50% - 3px);
}

.status-label.status-ok {
    background: var(--success);
    color: #fff;
}

.status-label.status-draft {
    background: var(--info);
    color: #fff;
}

.status-label.status-danger {
    background: var(--danger);
    color: #fff;
}

.status-label.status-warning {
    background: var(--warning);
}

.params {
    margin-bottom: 15px;
}

.params .group {
    margin: 2px 15px 2px 0;
}

.params .group .label {
    display: inline-block;
    color: #8999a3;
    font-weight: normal;
    margin: 0;
}

.params .group .item {
    display: inline-block;
    font-weight: bold;
}

.page-header .actions {
    margin: 15px 0 0 0; /* border-top: 1px solid #eee; */
    padding-top: 15px;
}

.page-header .actions .btn, .page-header .actions .btn-group {
    margin-bottom: 5px;
    vertical-align: top;
    margin-left: 5px;
}

.page-header .actions .btn:first-child {
    margin-left: 0;
}

.page-header .actions .btn-group .btn {
    margin-left: 0;
}

.content {
    padding: 0 25px;
}

.case {
    position: relative;
    background: #fff;
    margin-bottom: 35px;
    border-radius: 5px;
    min-height: 270px;
    /* border: 1px solid #d3d9df; */
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
}

.case.auto-height {
    min-height: 0;
}

.case:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    border: 5px solid #dfdfea;
    z-index: -1;
    border-radius: 3px;
    opacity: 0;
}

.case > .title {
    padding: 0 15px;
    min-height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;
    justify-content: space-between;
}

.case > .title.no-border {
    border: none;
}

.case > .title.clickable {
    cursor: pointer;
}

.case > .title[data-toggle="collapse"] {
    padding-left: 25px;
    cursor: pointer;
}

.case > .title[data-toggle="collapse"]:before {
    content: '';
    position: absolute;
    border: 4px solid transparent;
    border-top-color: inherit;
    margin-left: -15px;
    font-size: 8px;
    opacity: 0.8;
    transform-origin: center 3px;
}

.case > .title[data-toggle="collapse"][aria-expanded="true"]:before {
    transform: rotate(180deg);
}

.case > .title .name {
    font-size: 1.05rem;
    margin: 0;
    font-weight: 400;
    color: #3d4465;
}

.case > .inner {
    padding: 15px;
}

.case .case-nav .nav-tabs {
    border-bottom: none;
}

.case .case-nav .nav-tabs-line {
    width: 100%;
    padding: 0 25px;
    border-top: 1px solid #eee;

}

.case .case-nav .nav-tabs:not(.nav-tabs-line) {
    padding: 25px 0
}

.case .case-nav .nav-tabs-cols {
    padding: 25px;
}

.case .case-nav .nav-tabs-cols .nav-item {
}

.case .case-nav .nav-tabs-cols .nav-item a {
    border: none !important;
    border-radius: 5px;
    font-weight: bold;
}

.case .case-nav .nav-tabs-cols .nav-link {
}

.case .case-nav .nav-tabs-line .nav-item {
    margin-right: 4rem;
}

.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-item {
    width: 100%
}

.case .case-nav .nav-link {
    border-radius: 0;
    font-size: 130%;
    margin: 0;
    font-weight: 500;
    color: #646c9a;
    border: 0;
}

.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-link {
    font-size: 120%;
    padding: 10px 25px;
    width: 100%;
    border-left: 2px solid transparent;

}

.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-link .svg-inline--fa {
    width: 25px;
    margin-right: 8px;
    color: #ccc;
}

.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-link:hover .svg-inline--fa {
    color: inherit;
}

.case .case-nav .nav-tabs-line .nav-link {
    padding: 15px 0;
    border-bottom: 2px solid transparent;
}

.case .case-nav .nav-link:hover,
.case .case-nav .nav-link.active {
    background: transparent;
    color: #5d78ff;
}

.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-link:hover,
.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-link.active {
    background: #f7f8fa;
}

.case .case-nav .nav-tabs-line .nav-link.active {
    border-bottom-color: inherit;
}

.case .case-nav .nav-tabs:not(.nav-tabs-line) .nav-link.active {
    border-left-color: inherit;
}

.client-block {
}

.client-block.finance-top-section {
    background: #eee;
    padding: 30px;
    min-height: 150px;
    margin-bottom: 15px;
}

.client-block .name {
    font-size: 140%;
    font-weight: bold;
    margin-bottom: 15px;
    line-height: 1.2;
}

.client-block .balance {
}

.client-block .balance .client-money {
    font-size: 150%;
    color: var(--success);
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;
}

.client-block .balance .client-money.bad {
    color: var(--danger);
}

.client-block .balance .client-money .svg-inline--fa {
    font-size: 80%;
}

.payments-lines {
    width: calc(100% - 90px);
    position: relative;
    height: 10px;
    font-size: 120%;
    font-weight: bold;
    margin: 50px 20px;
    cursor: default;
}

.payments-lines .svg-inline--fa {
    font-size: 75%
}

.payments-lines .line {
    position: absolute;
    left: 0;
    top: 0px;
    border-top: 8px solid;
}

.payments-lines .line .from {
    color: #888;
    font-size: 80%;
    position: absolute;
    right: calc(100% + 5px);
    top: -15px;
    white-space: nowrap;
}

.payments-lines .line .to {
    white-space: nowrap;
    color: #888;
    position: absolute;
    left: calc(100% + 5px);
    top: -15px;
    font-size: 80%;
}

.payments-lines .line .now {
    text-align: center;
    position: relative;
    top: -32px;
    white-space: nowrap;
}

.payments-lines .line.main-sum {
    width: 100%;
    border-color: #ced4d9;
}

.payments-lines .line.remainder-sum {
    left: auto;
    right: 0;
    color: var(--danger);
}

.payments-lines .line.paid-sum {
    color: var(--success);
}

.payments-lines .line.delivered-sum {
    color: #d2dae6;
    top: 8px;
}

.payments-lines .line.delivered-sum .now {
    top: 0;
}

.btn-success {
    border-color: transparent;
}

.btn-success:hover {
    color: #fff;
    background-color: var(--success);
    border-color: var(--success);
}

.form-control, .custom-select {
    border-color: #eee;
    background-color: #efefef;
    outline: none !important;
    color: inherit;
    font-size: 0.9rem;
}

.form-control:focus, .custom-select:focus {
    border-color: #5d77ff;
    outline: none !important;
    box-shadow: none;
    background-color: #eee;
}

.form-control .action {
    font-size: 90%;
    cursor: pointer;
    opacity: 0.8;
}

.form-control .action:hover {
    opacity: 1;
}

*:disabled {
    cursor: not-allowed;
}

.form-danger {
    border-color: #e25164;
}

.no-data-message {
    font-size: 200%;
    font-weight: bold;
    color: #eee;
}

.item .table-actions {
    position: absolute;
    width: 30px;
    left: -35px;
    padding: 0 8px;
    display: flex;
    height: 100%;
    align-items: center;
    top: 0;
}

.item .table-actions ~ .title {
    padding-left: 30px
}

.item .table-actions .custom-control.custom-checkbox {
    padding-left: 8px;
}

.item .table-actions ~ .row {
    /* padding-left: 30px; */
}

.item .table-actions ~ .row > div:first-child {
    /* padding-left: 40px; */
}

.item .table-actions label {
    display: block;
    margin: 0 0 0 15px;
}

.action-btn {
    font-size: 16px;
}

.action-btn.action-delete {
    color: var(--danger) !important;
}

.action-btn.action-danger {
    color: var(--danger) !important;
}

.action-btn.action-warning {
    color: var(--warning) !important;
}

.action-btn.action-info {
    color: var(--info) !important;
}

.action-btn.action-success {
    color: var(--success) !important;
}

.table-status {
    font-size: 12px;
    position: relative;
    display: inline-flex;
    line-height: 1.2;
    text-align: center;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
}

.table-status.lines {
    position: absolute;
    left: 0;
    right: 0;
    top: -8px;
    bottom: -8px;
    justify-content: flex-end;
    padding-right: 20px;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 5px;
}

.table-status.lines .circle {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: auto;
    width: 10px;
    border-radius: 0;
    margin: 0;
}

.opacity-10 {
    opacity: 0.1;
}

.opacity-20 {
    opacity: 0.2;
}

.opacity-30 {
    opacity: 0.3;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-80 {
    opacity: 0.8;
}

.opacity-90 {
    opacity: 0.9;
}

.table-dash {
    margin: 20px 0 40px 0;
}

.table-input {
    max-width: 80px;
    border: none;
    background: transparent;
    margin-left: 3px;
}

.table-input-control {
    border: 1px solid transparent;
    padding: 0;
    width: 100%;
    border-radius: 3px;
    outline: none;
}

.table-input-control.danger {
    border-color: #f38084;
}

.item.add-new .table-input-control {
    background: #efefef;
    min-height: 30px;
    padding: 0 8px;
}

.item.add-new .table-create {
    background: transparent;
    border: none;
    font-size: 25px;
    color: #ddd;
    padding: 0;
    margin: 0;
    display: flex;
    transition: all .3s ease;
}

.item.add-new .table-create:hover {
    color: #289eeb;
    cursor: pointer;
}

[data-sort] {
    cursor: pointer;
    transition: all .3s;
}

[data-sort]:hover {
    color: #555;
}

[data-sort] .svg-inline--fa {
    color: #289eeb;
    vertical-align: middle;
    margin-left: 5px;
}

.header-search {
    position: relative;
}

.header-search input {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    font-weight: bold;
}

.header-search label {
    position: absolute;
    left: 4px;
}

.header-search input:focus, .header-search input.active {
    border-color: #2da8ee;
    padding: 2px 5px;
}

.header-search input:focus ~ label, .header-search input.active ~ label {
    opacity: 0;
}

.order-search {
    margin-bottom: 40px;
    padding: 30px 0;
    background: white;
}

.order-search .title {
    margin-bottom: 25px;
    text-align: center;
    color: #888;
}

.order-search .title .svg-inline--fa {
    font-size: 80%;
    margin-right: 5px;
    color: #2da8ee;
}

.custom-btn-checkbox {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.custom-btn-checkbox input {
    display: none;
}

.custom-btn-checkbox label {
    display: inline-block;
    cursor: pointer;
}

.custom-btn-checkbox input:checked ~ label {
    background-color: #ffcc88 !important;
    border-color: #ffcc88 !important;
}

.inputs-group {
    display: flex;
    max-width: 100%;
    overflow: hidden;
}

.inputs-group input {
    display: inline-flex;
    width: 100%;
    background: #f1f3f4;
    border: 1px solid #e6e6e6;
    text-align: center;
    padding: 8px;
}

.inputs-group input:nth-child(1) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.inputs-group input:nth-child(2) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.range-group {
    position: relative;
}

.range-group input {
}

.range-group input:nth-child(1) {
}

.range-group input:nth-child(2) {
    position: relative;
    top: -20px;
}

.custom-range::-webkit-slider-thumb {
    position: relative;
    z-index: 1;
}

.label, label {
    font-weight: 100;
    margin-bottom: 5pt;
}

.btns-margin-group .btn {
    margin-right: 10px;
}

.page-nav-tabs {
}

.page-nav-tabs .link {
    padding: 3px 8px;
    color: #959cb6;
    text-decoration: none !important;
    display: inline-block;
    border-bottom: 0;
    border-radius: 5px;
}

.page-nav-tabs .link:hover {
    background: #eee;
}

.page-nav-tabs .link.active {
    background: #5887ec;
    color: #fff;
}

.page-header.have-tabs {
    /* padding-bottom: 80px; */
}

.page-header.have-params {
    margin-bottom: 0;
    border-bottom: 0;
}

.page-header-params {
    padding: 0 40px;
    border-top: 1px solid #eff1f4;
    background: #fff;
    margin-bottom: 40px;
}

.page-header-params .params {
    margin-bottom: 0px;
}

.page-header-params .params .group {
    margin: 0;
    padding: 15px;
    border-left: 1px solid #eff1f4;
    cursor: default;
}

.page-header-params .params .group.group-danger {
    background: var(--danger);
    color: #fff;
}

.page-header-params .params .group.group-danger .label {
    color: #fff
}

.page-header-params .params .group:last-child {
    border-right: 1px solid #eff1f4;
}

.page-header-params .params .group.clickable {
    cursor: pointer;
    transition: all .3s ease;
    padding: 0;
}

.page-header-params .params .group.clickable .dropdown-toggle {
    padding: 15px
}

.page-header-params .params .group.clickable .dropdown-toggle:hover,
.page-header-params .params .group.clickable .dropdown-toggle[aria-expanded="true"] {
    background: #2b393f;
    color: #fff;
}

.page-header-params .params .group.clickable .dropdown-toggle:hover .label,
.page-header-params .params .group.clickable .dropdown-toggle[aria-expanded="true"] .label {
    color: #eee;
}

.item.body-title {
    font-weight: bold;
    font-size: 110%;
    color: #6292ee;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgb(246, 249, 251);
}

.input-btn-group {
    width: 100%;
}

.input-btn-group input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.input-btn-group a {
}

.input-btn-group .dropdown-toggle[disabled] {
    background: #eee;
    color: #222;
    border: 1px solid #ced4d9;
    cursor: not-allowed;
}

.input-btn-group .dropdown-toggle {
    width: 40px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
}

.input-btn-group .dropdown-menu .dropdown-item {
    cursor: pointer;
}

.input-btn-group .dropdown-menu .svg-inline--fa {
    opacity: 0.4;
    margin-right: 8px;
    width: 20px;
}

.client-money [data-icon] {
    color: #afb5ce;
}

.client-money [data-icon="ruble-sign"] {
    color: inherit;
}

.dropdown-item {

    cursor: pointer;
}

.custom-select-search .dropdown-menu {
    min-width: 15rem;
}

.dropdown-item .svg-inline--fa {
    margin-right: 8px;
}

.main.motion-sidebar .fixed-bottom-toolbar {
    left: 60px;
}

/*fixed-bottom-toolbar*/
.fixed-bottom-toolbar {
    position: fixed;
    left: 200px;
    right: 0;
    bottom: 0;
    padding: 5px 50px;
    background: #b0dab7;
    transition: all .3s ease-in-out;
    transform: translateY(100%)
}

.fixed-bottom-toolbar .btn .badge {
    margin-left: 8px;
}

.fixed-bottom-toolbar.active {
    transform: translateY(0)
}

/*v-select*/
.v-select {
}

.v-select .dropdown-toggle {
    background: #f1f3f4 !important;
    border-radius: 3px !important;
    padding: 0 !important;
}

.v-select input {
    margin: 0 !important;
}

.v-select .dropdown-toggle:after {
    display: none;
}

/*custom-select-search*/
.custom-select-search {
    position: relative;
    border-radius: 0.26rem;
    background-color: #efefef;
    border: 1px solid transparent;
}

.custom-select-search-header {
    position: relative;
}

.custom-select-search-header .clear-data {
    position: absolute;
    color: var(--danger);
    font-size: 90%;
    right: 30px;
    top: 10px;
    opacity: 0.5;
    cursor: pointer;
}

.custom-select-search-header .clear-data:hover {
    opacity: 1;
}

.custom-select-search-header.dropdown-toggle::after {
    position: absolute;
    right: 12px;
    top: 18px;
}

.custom-select-search .custom-select-search-input ~ .placeholder {
    position: absolute;
    top: 8px;
    left: 15px;
    right: 50px;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
    font-size: 0.9rem;
}

.custom-select-search .custom-select-search-input ~ .placeholder {

}

.custom-select-search .custom-select-search-input ~ .placeholder:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 15px;
    background: linear-gradient(to left, #efefef, transparent);
}

.custom-select-search.bg-danger .custom-select-search-input ~ .placeholder:after {
    background: linear-gradient(to left, var(--danger), transparent);

}

.custom-select-search .custom-select-search-input {
    padding-right: 45px;
    background: inherit;
    color: inherit;
}

.custom-select-search .custom-select-search-input.active ~ .placeholder {
    display: none;
}

.input-group > .custom-select-search {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group .custom-select-search:last-child input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group .custom-select-search:not(:last-child):not(:first-child) input {
    border-radius: 0;
}

.input-group .custom-select-search:first-child input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dashed-btn {
    padding: 2px 8px;
    border: 1px dashed #399bf0;
    display: inline-block;
    border-radius: 3px;
    transition: all .3s ease-in-out;
    text-decoration: none !important;
    font-weight: bold;
}

.add-to-order {
    background: transparent;
    border: none;
    font-size: 14pt;
    color: #399bf0;
    cursor: pointer;
    display: flex;
}

.add-to-order:hover {
    color: #197cd2;

}

.table-strong {
    display: flex;
    font-weight: bold;
}

.table-strong .common {
    margin-right: 5px;
}

.table-strong .common.ok {
    color: var(--success);
}

.table-strong .common.bad {
    color: var(--danger);

}

.table-strong .secondary {
    opacity: 0.6;
}

[data-icon="ruble-sign"] {
    font-size: 90%;
}

.tooltip-body {
    display: flex;
    position: absolute;
    left: 14%;
    right: 14%;
    top: 14%;
    bottom: 14%;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 0;
    overflow: auto;
    border-radius: 100%;
}

.tooltip-body:hover {
    border-radius: 0;
}

.tooltip-body .tooltip-elem {
    line-height: 1.2;
}

.tooltip-body .tooltip-elem .title {
    display: block;
    text-align: center;
    color: #afb5ce;
    font-size: 100%;
    text-transform: uppercase;
    margin: 0
}

.tooltip-body .tooltip-elem .data {
    text-align: center;
    display: block;
    font-size: 120%;
    font-weight: bold;
}

.tooltip-body .tooltip-elem .data.bad {
    color: var(--danger);
}

.mini-title {
    font-size: 100%;
    color: #7f8183;
    font-weight: normal;
}

.block-has-right-divider {
    border-right: 1px solid rgba(175, 181, 206, 0.23);
}

.client-order-item {
    padding: 10px 0;
    border-bottom: 1px solid #eff1f4;
}

.client-data .item {
    padding: 8px 0;
    border-bottom: 1px solid #eff1f4;
}

.client-data .label {
    color: #afb5ce;
    margin: 0;
}

.client-data .text {
    font-weight: bold;
}

.case .title .action button {
    border: none;
    background: transparent;
    color: #3992ff;
}

.case .case-footer {
    padding-top: 25px;
}

.creating-form {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500px;
    background: #b0dab7;
    z-index: 300;
    padding: 45px;
    overflow: auto;
}

.creating-form .dash {
    margin-bottom: 50px;
}

.creating-form .inner {

}

.alert .title {
    color: #45454d;
    font-size: 120%;
    font-weight: bold;
    text-transform: uppercase;
}

.alert.callout {
    padding: 15px;
    background: rgba(255, 204, 136, 0.3);
    margin: 15px 0;
}

.alert.callout:before {
    content: '';
    position: absolute;
    border-left: 5px solid #b0690026;
    top: 0;
    bottom: 0;
    left: 0;
}

.alert.callout:after {
}

/*info-tooltop*/
.info-tooltip {
    position: absolute;
    background: #2e353b;
    padding: 8px 25px;
    width: 335px;
    color: #fff;
    z-index: 9999;
    /* margin-top: 15px; */
    display: none;
    left: calc(-25% - 138px);
    border-radius: 3px;
}

.info-tooltip:hover {
    display: block;
}

.info-tooltip:before {
    content: '';
    position: absolute;
    border-bottom: 10px solid #2e353b;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: calc(50% - 15px);
    top: -10px;
}

.info-tooltip .row {
    padding-top: 8px;
    padding-bottom: 8px;
}

.info-tooltip .name {
    color: #d3d9de !important;
    font-size: 100% !important;
    text-transform: none !important;
    font-weight: normal !important;
}

.info-tooltip .value {
    font-weight: bold;
}

.info-tooltip-group {
    position: relative;
}

.info-tooltip-toggle {
}

.info-tooltip-toggle:hover {
}

.info-tooltip-toggle:hover ~ .info-tooltip {
    display: block
}

.info-tooltip-toggle.info {
    color: #0492f8;
}

/*charts-group*/
.charts-group {
    position: relative;
    max-width: 180px;
    overflow: auto;
    margin: 0 auto;
    height: 180px;
}

.charts-group .main_chart {
    border-radius: 0;
    overflow: auto;
}

.charts-group .main_chart:hover ~ .tooltip-body .secondary_chart {
    opacity: 0
}

.charts-group .secondary_chart {
    position: absolute;
    top: 0;
    transition: all .3s ease;
    border-radius: 100%;
}

.charts-group .secondary_chart:hover {
    border-radius: 0;
}

.custom-range::-webkit-slider-thumb {
    background-color: #15c789;
}

.custom-range[data-percent] {
    position: relative;
    margin-top: 35px
}

.custom-range[data-percent]:before {
    content: attr(data-percent) ' %';
    position: absolute;
    width: 50px;
    text-align: center;
    left: calc(50% - 25px);
    top: -25px
}

.storages {
}

.storages .item {
    margin: 15px 0 30px 0;
    background: #fff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: 10px solid #ffc107;
}

.storages .item.ready {

    border-left-color: #4bc780;
}

.storages .cell_btn {
    margin-bottom: 15px;
}

.storages .cell_btn .svg-inline--fa:hover {
    color: #0488f7;
}

.storages .item .cells {
    padding: 25px 15px;
}

.hr-dashed {
    width: 100%;
    margin: 100px 0 40px 0;
    border-bottom: 2px dashed #ddd;
}

/*.pagination-numbers*/
.pagination-case {
    margin: 15px 0 0 0;
}

.pagination-case .pagination {
    margin: 0;
}

.pagination-case .page-link {
    color: inherit;
}

.pagination-case .page-link:disabled {
    background: #eff0f5;
    color: #aaa;
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-case .svg-inline--fa {
    color: #057bff;
    font-size: 80%;
}

.pagination-numbers {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #dee2e6;
    font-weight: bold;
}

.per-page-items {
}

.per-page-items .label {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    background: #fff;
    border: 1px solid #dee2e6;
    font-weight: bold;
    margin: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.per-page-items input {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    background: #fff;
    border: 1px solid #dee2e6;
    width: 60px;
    margin: 0 -1px 0 -1px;
    color: #057bff;
    font-weight: bold;
    outline: none !important;
}

.per-page-items .from {
    margin-left: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    background: #fff;
    border: 1px solid #dee2e6;
}

.page-filter {
    background: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    align-items: center;
    margin-bottom: 8px;
    border: 1px solid #d9e3ea;
}

.page-filter .form-group {
    margin: 0;
}

.page-filter .inner {
}

.open-close-filter-btn {
    margin: 0 auto;
    display: block;
    background: #fff;
    border: 1px solid #eee;
    padding: 10px 15px;
    margin-top: -1px;
    border-top: 1px solid #fff;
    outline: none !important;
    cursor: pointer;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.open-close-filter-btn .svg-inline--fa {
    font-size: 90%;
    color: #057bff;
}

.open-close-filter-btn:hover .svg-inline--fa {
    color: #fff;
}

.open-close-filter-btn:hover {
    background: #057bff;
    color: #fff;
}

.btn-clean {
    background: transparent !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

/*media-browser-item*/
.media-browser-item {
    background: #fff;
    margin-bottom: 30px;
    position: relative;
}

.media-browser-item:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    border: 5px solid transparent;
    cursor: pointer;
    transition: all .3s ease;
}

.media-browser-item.selected:before {
    border-color: rgb(40, 158, 235);
}

.media-browser-item .selected-icon {
    position: absolute;
    z-index: 2;
    color: rgb(40, 158, 235);
    top: 12px;
    right: 12px;
    font-size: 20px;
}

.help {
    opacity: 0.4;
    margin: 15px 0;
    text-align: center;
}

.media-browser-item .thumbnail {
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
}

.media-browser-item .thumbnail .inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-align: center;
}

.media-browser-item .thumbnail .svg-inline--fa {
    font-size: 35px;
    text-align: center;
    color: #58b7f9;
}

.media-browser-item .thumbnail .name {
    margin-top: 15px;
    font-size: 110%;
}

.media-browser-item .thumbnail:hover {
    opacity: 1;
}

.media-browser-item .title {
}

.media-browser-item .props {
}

.media-browser-item-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    padding: 40px;
}

.media-browser-item-modal .overflow {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(29, 29, 29, 0.8);
    cursor: pointer;
}

.media-browser-item-modal .header {
    background: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 30px;
    border-bottom: 1px solid #ddd;
    height: 54px;
}

.media-browser-item-modal .modal-title {
}

.media-browser-item-modal .modal-container {
    position: absolute;
    left: 40px;
    right: 40px;
    top: 40px;
    bottom: 40px;
    background: #fff;
    overflow: hidden;
}

.media-browser-item-modal .modal-container .picture {
    /* position: absolute; */
    left: 30px;
    right: 0;
    bottom: 15px;
    top: 15px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 500px;
    width: 100%;
}

.media-browser-item-modal .modal-container .settings {
    padding: 25px;
    background: #eee;
    height: 100%;
}

.media-browser-item-modal .modal-container .settings .props {
    padding: 15px 0;
}

.media-browser-item-modal .modal-container .settings .props ul {
    padding: 0;
}

.media-browser-item-modal .modal-container .settings .props .name {
    font-weight: bold;
    margin-right: 15px;
}

.media-browser-item-modal .modal-container .settings .form {
    width: 100%;
}

.media-browser-item-modal .files-list {
    position: relative;
    overflow: auto;
    padding: 30px 30px 80px 30px;
    height: 100%;
}

.row-media-browser {
    position: absolute;
    bottom: 0;
    top: 54px;
    left: -15px;
    right: -15px;
    margin: 0;
    overflow: auto;
    pointer-events: all;
    z-index: 1;
}

.media-browser {
}

.media-browser .upload {
    text-align: center;
    margin-bottom: 40px;
    padding: 20px;
    background: #eee;
}

.table-logo {
    width: 20px;
    height: 20px;
    background: center no-repeat;
    background-size: contain;
    margin: 0 8px 0 0;
}

.history-tree {
    position: relative;
    border-left: 2px solid #aaa;
}

.history-tree .tree-item:before,
.history-tree .tree-item:after {
    content: '';
}

.history-tree .tree-item:before {
    position: absolute;
    left: -7px;
    top: calc(50% - 6px);
    border: 2px solid #0585ff;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #fff;
    z-index: 1;
    transition: all .2s ease-in-out

}

.history-tree .tree-item:after {
    z-index: 0;
    width: 20px;
    border-bottom: 2px solid #0585ff;
    left: 0;
    position: absolute;
    top: calc(50% - 1px);
}

.history-tree .tree-item {
    padding: 8px 30px;
    position: relative;
    transition: all .2s ease-in-out
}

.history-tree .tree-item.has-link {
    color: #0585ff;
    cursor: pointer;
}

.history-tree .tree-item:hover {
    background: #efefef;
}

.history-tree .tree-item:hover:before {
    background: #0585ff;
}

.history-tree.storage {
    border: none;
    padding: 10px;
}

.history-tree.storage .tree-item:after {
    bottom: 0;
    border-color: #aaa;
}

.history-tree.storage .tree-item {
    border-left: 2px solid #aaa;
    padding-left: 15px;
    font-weight: bold;
}

.history-tree.storage .tree-item:nth-child(1) {

}

.history-tree.storage .tree-item:nth-child(2) {
    margin-left: 20px;
}

.history-tree.storage .tree-item:nth-child(3) {
    margin-left: 40px;
}

.history-tree.storage .tree-item:nth-child(1):before {
    border-color: #651ec9;
    bottom: 0;
}

.history-tree.storage .tree-item:nth-child(2):before {
    border-color: #417dc9;
}

.history-tree.storage .tree-item:nth-child(3):before {
    border-color: #45c9a3;
}

.department-point {
    background: #eee;
    border-radius: 3px;
    margin-bottom: 30px;
    padding: 15px 60px 15px 15px;
    position: relative;
}

.department-point .actions {
    position: absolute;
    top: 15px;
    right: 15px;
}

.department-point .name {
    font-size: 120%;
    font-weight: bold;
}

.department-point .description {
    font-size: 90%;
    font-style: italic;
}

/*catalog*/
.catalog {
    /* margin: 40px 0; */
    background: #fff;
}

.catalog .feed-list-header,
.catalog .feed-list-body {
    border-radius: 0;
    margin-left: -1px;
}

.catalog + .catalog-after-dash {
    margin: -15px 0 40px 0;
    text-align: center;

}

.catalog .folders {
    border-top: 1px solid #d9e3ea;
    border-left: 1px solid #d9e3ea;
}

.catalog .folder .name {
    position: relative;
    border-bottom: 1px solid #d9e3ea;
    border-right: 1px solid #d9e3ea;
}

.catalog .folder .name:after {
    content: '';
    border: 4px solid transparent;
    border-bottom: none;
    position: absolute;
    border-top-color: #aaa;
    top: calc(50% - 2px);
    right: 15px;
    transition: all .3s ease;
}

.catalog .folder .name[aria-expanded="true"]:after {
    transform: rotate(180deg);
    border-top-color: #0579ff;
}

.catalog .sub .folder .name:after,
.catalog .folder.folder-new .name:after {
    display: none;
}

.catalog .folder .name .inner {
    cursor: pointer;

    padding: 10px 15px;
    transition: all .2s ease;
    position: relative;
    width: 100%;
}

.catalog .folder .name .inner input {
    width: calc(100% - 50px);
}

.catalog .folder .name .actions {
    position: absolute;
    right: 28px;
    top: 10px;
}

.catalog .folder .name .actions .item {
    opacity: 0.5;
    cursor: pointer
}

.catalog .folder .name .actions .item:hover {
    opacity: 1;
}

.catalog .folder .name .inner:hover {
    background: #eee;
}

.catalog .folder .sub .folder .name .inner {
    padding-left: 30px;
}

.catalog .svg-inline--fa {
    width: 25px;
}

.catalog .folder .svg-inline--fa {
    color: #ddd;
    transition: all .2s ease;
}

.catalog .folder .name:hover .inner .svg-inline--fa,
.catalog .folder .name.active .inner .svg-inline--fa {
    color: #057aff !important;
}

.catalog .folder .name[aria-expanded="true"] .inner .svg-inline--fa {

}

.catalog .folder.folder-new .name .inner {
    color: #00c071;
    opacity: 0.4;
}

.catalog .folder.folder-new:hover .name .svg-inline--fa {
    color: inherit !important;
}

.catalog .folder.folder-new .name .inner:hover {
    opacity: 1;
}

.catalog .folder.folder-new .name .svg-inline--fa {
    color: inherit !important;
}

.catalog .items .header {
    font-weight: bold;
    background: #eee;
    padding: 10px 30px;
}

.catalog .items .work {
    position: relative;
    transition: all .3s ease;
    padding: 10px 30px;
    border-bottom: 1px solid #ddd;
}

.drag-point {
    color: #ddd;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-left: 1px dotted;
    border-right: 1px dotted;
    overflow: hidden;
    width: 11px;
    cursor: all-scroll;
    z-index: 1;
}

.drag-point:before,
.drag-point:after {
    position: absolute;
    content: '';
    left: 1px;
    top: 0;
    right: 1px;
    bottom: 0;
    border-left: 1px dotted;
    border-right: 1px dotted;
}

.drag-point:after {
    left: 3px;
    right: 3px;
}

.drag-point:hover {
    color: #617bff;
}

.catalog .items .work .table-input-control[name="price"] {
    text-align: right;
}

.feed-of-items .create {
    color: #00c071;
    cursor: pointer;
    padding: 8px 0;
}

.feed-of-items .create:hover {
    color: #fff;
    background: #00c071;
}

.feed-of-items .work.add input {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.feed-of-items .work.add {
    padding: 15px;
}

.feed-of-items .work.add .actions {
    margin: 15px 0;
}

.feed-of-items .work.add .actions .btn {
    margin-left: 15px;
}

.catalog .catalog-create {
    background: #eee;
    padding: 15px;
}

/*suggestions-table*/
.suggestions-table {
}

.suggestions-table .item {
    padding: 10px 0;
    border-bottom: 1px solid #d9dee2;
}

.suggestions-table .item.is_complete {
    opacity: 0.5;
    transition: all .3s ease;
}

.suggestions-table .item.is_complete:hover {
    opacity: 1
}

/*schedule*/
.schedule {
    position: relative;
    /* right: 0; */
    /* left: 0; */
    /* top: 0; */
    /* bottom: 0; */
    /* overflow: auto; */
}

.schedule .left-side {
    background: #eee;
    border-radius: 5px;
    position: relative;
    left: 25px;
    width: calc(100% - 65px);
}

.main.pressed .schedule .left-side {
    left: 0;
    width: calc(100% - 275px);
}

.schedule .right-side {
    width: 275px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    top: 80px;
    z-index: 0;
    /* border-left: 1px solid #ddd; */
    /* border-top: 1px solid #ddd; */
    overflow: auto;
    z-index: 1;
    transition: all .3s ease;
    box-shadow: 0 0 25px #ddd;
    background: #fff;
    border-radius: 5px;
    padding-left: 20px;
}

.schedule .right-side .settings {
    width: 100%;
    position: relative;
}

.schedule .right-side:not(.active) .settings {
    display: none;
}

.schedule .right-side:not(.active) {
    right: -255px;
}

.schedule .right-side:not(.active) .schedule-toggle {
    background: #607aff;
    color: #fff;
}

.schedule .right-side .schedule-toggle {
    position: fixed;
    width: 20px;
    display: flex;
    z-index: 1;
    background: #aaa;
    color: #fff;
    justify-content: center;
    align-items: center;
    top: 80px;
    bottom: 20px;
    margin: 0 0 0 -20px;
    border-right: 1px solid #ddd;
    transition: all .3s ease;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.schedule .right-side .schedule-toggle:hover {
    pointer-events: all;
    cursor: pointer;
    background: #607aff;
    color: #fff;
}

.schedule .head {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    left: 0;
    right: 0;
}

.schedule .head .date-change {
}

.schedule .body {
    position: relative;
    padding-left: 60px;
}

.schedule .body .time-pills {
    position: absolute;
    left: 0;
    top: 84px;
    right: 0;
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding-bottom: 30px;
}

.schedule .body .time-pills .item {
    height: 30px;
    display: flex;
    align-items: flex-end;
    padding: 0px 0 0 15px;
    line-height: 0;
    cursor: default;
    transition: all .2s ease-in-out;
    color: #444;
}

.schedule .body .time-pills .item.now {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}

.schedule .body .time-pills .item.now:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: #3F51B5;
    border-radius: 100%;
    left: 50px;
    z-index: 10;
    top: calc(100% - 4px);
}

.schedule .body .time-pills .item.now:after {
    border-bottom: 1px solid #3F51B5 !important;
}

.schedule .body .time-pills .item:hover:after {

    border-bottom: 1px solid #22ace6 !important;
}

.schedule .body .time-pills .item:hover {
    color: #22ace6 !important;
}

.schedule .body .time-pills .item:after {
    content: '';
    position: absolute;
    left: 50px;
    transition: all .2s ease-in-out;
    right: 0;
    border-bottom: 1px solid #ccc;
}

.schedule .body .time-pills .item.item-sub {
    color: #aaa;
    border-color: red;
}

.schedule .body .time-pills .item.item-sub:after {
    border-bottom: 1px dashed rgba(221, 221, 221, 0.77);
    border-color: #ccc;
}

.schedule .body .departments {
    z-index: 1;
    position: relative;
    width: 100%;
    padding-bottom: 150px;
    overflow: auto;
}

.schedule .body .departments:after {
    content: '';
    position: absolute;
    width: 50px;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent, white);
}

.schedule .body .departments > .inner {
    transform: translateX(0);
    transition: all .3s;
    display: inline-flex;
}

.schedule .body .departments > .inner > .item {
    width: 170px;
    border: 1px dashed #ccc;
    border-top: 0;
    transition: all .2s ease;
    display: inline-block;
}

.schedule .body .departments > .inner > .item:nth-child(2n-1) {
    /* background: rgba(96, 122, 255, 0.07); */
}

.schedule .body .departments .item:hover {
    background: rgba(238, 238, 238, 0.4);
}

.schedule .body .departments .item:not(:first-child) {
    border-left: 0;
}

.schedule .body .departments .item .pills {
    height: 720px;
    position: relative;
}

.schedule .body .departments .item .pills > .time-sectors {
    position: absolute;
    width: 100%;
}

.schedule .body .departments .item .pills > .time-sectors .item {
    height: 30px;
    width: 100%;
    cursor: pointer;
}

.schedule .body .departments .item .pills > .time-sectors .item:hover {
}

.schedule .body .departments .item .pills .write-box {
}

.schedule .body .departments .item .pills .write-box .dropdown-menu {
    /* position:fixed; */
    /* margin: -350px 0 0 -120px; */
}

.schedule .body .departments .item > .name {
    height: 84px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding-top: 15px;
    top: 0;
}

.schedule .body .departments .item .load {
    overflow: hidden;
    width: 80%;
    position: absolute;
    bottom: 8px;
    height: 20px;
    background: #eee;
    border-radius: 5px;
}

.schedule .body .departments .item .load .progress {
    position: absolute;
    background: rgba(53, 135, 239, 0.32);
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
}

.schedule .body .departments .item .load:before {
    content: attr(data-load);
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    width: 100%;
    display: inline-block;
    font-weight: bold;
    opacity: 0.7;
    position: relative;
    z-index: 2;
    line-height: 1;
}

.schedule .body .departments .item .name .title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 90%;
    text-align: center;
    padding: 0 15px;
    width: 100%;
}

.schedule .body .departments .item .name .title .color-dot {
    float: none;
    width: 8px;
    height: 8px;
    margin-right: 5px;
}

.schedule .body .departments .item .pills .write {
    position: absolute;
}

.schedule .body .departments .item .pills .write.cancelled:not(:hover) .inner {
    background: #ddd;
    color: #888;
    box-shadow: none;
}

.schedule .body .departments .item .pills .write .inner {
    overflow: hidden;
    margin: 4px 4px 0 4px;
    /* border-top: 4px solid; */
    background: #aaa;
    width: calc(100% - 8px);
    transition: all .3s ease-in-out;
    line-height: 1.4;
    background: #fff;
    box-shadow: 0 0 9px rgba(37, 42, 57, 0.3);
    border-radius: 5px;
    padding: 0 12px 15px 12px;
}

.schedule .body .departments .item .pills .write:hover {
    z-index: 100;
}

.schedule .body .departments .item .pills .write:hover .inner {
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.34);
}

.schedule .body .departments .item .pills .write .title {
    font-weight: bold;
    padding: 15px 0 7px 0;
    transition: all .3s ease;
}

.schedule .body .departments .item .pills .write.mini .title {
    padding: 0 0 7px 0;
}

.schedule .body .departments .item .pills .write.mini:hover .inner {
    height: auto !important;
}

.schedule .body .departments .item .pills .write.mini:hover .title {
    padding: 12px 0 7px 0;
}

.schedule .body .departments .item .pills .write .time {
    padding: 0 0 10px 0;
    opacity: 0.4;
}

.schedule .body .departments .item .pills .write .car {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 15px 0;
    opacity: 0.4;
    margin-bottom: -1px;
}

.schedule .body .departments .item .pills .write:hover .car {
    opacity: 1;
}

.schedule .body .departments .item .pills .write:hover .time {
    opacity: 1;
}

.schedule .body .departments .item .pills .write .car .model {
    color: #111;
}

.schedule .body .departments .item .pills .write .car .car_number {
    color: #111;
}

.schedule .body .departments .item .pills .write .car .car_vin {
    color: #111;
}

.schedule .body .departments .item .schedule-correct {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #27323833;
}

.schedule .body .departments .item .schedule-correct .case {
    width: 600px;
    background: #fff;
    top: 100px;
    left: 15px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

/*.vdpInnerWrap*/
.vdpComponent {
}

.vdpComponent .vdpClearInput {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vdpComponent .vdpClearInput:before {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: red;
    color: #fff;
    height: 15px;
    width: 15px;
    opacity: 0.4;
    margin: 0;
    position: static;
}

.vdpComponent.hide-clear-btn .vdpClearInput {
    display: none !important;
}

.vdpInnerWrap {
    /* min-width: 100% !important; */
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
}

.sidebar .vdpInnerWrap {
    min-width: auto;
}

.vdpArrowPrev:after {
    border-right-color: #43434c !important;
}

.vdpArrowNext:after {
    border-left-color: #43434c !important;
}

.vdpHeader {
    /* background: transparent !important; */
}

.vdpArrow {
}

.vdpCell.selected .vdpCellContent {
    color: #fff;
    background: #3687ef !important;
}

.vdpCell.today {
    color: #3687ef !important;
}

.vdpCell.selectable:hover .vdpCellContent {
    color: #fff;
    background: #3687ef !important;
}

.schedule .body .departments .arrow {
    position: absolute;
    background: #fff;
    transition: all .3s ease;
    color: #3687ef;
    height: 40px;
    width: 40px;
    border: none;
    box-shadow: 0 0 10px rgba(3, 18, 37, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    top: 20px;
    z-index: 200;
    /* padding-bottom: 4px; */
}

.schedule .body .departments .arrow:hover {
    background: #3687ef;
    color: #fff;
}

.schedule .body .departments .arrow.left {
    left: 5px;
}

.schedule .body .departments .arrow.right {
    right: 30px;
}

.schedule .calendar {
    border-bottom: 1px solid #ddd;
}

.schedule .calendar .vdpInnerWrap {
    min-width: 100%;
}

.schedule .filters {
    padding: 15px;
}

.slide-checkbox {
    position: relative;
    width: 100%;
}

.slide-checkbox input {
    display: none;
}

.slide-checkbox label {
    position: relative;
    cursor: pointer;
    padding-left: 40px;
    width: 100%;
}

.slide-checkbox label:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 18px;
    background: #ddd;
    border-radius: 15px;
    left: 0;
}

.slide-checkbox label:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background: #ffffff;
    border-radius: 15px;
    left: 3px;
    top: 2px;
    transition: all .3s ease;
}

.slide-checkbox input:checked ~ label:after {
    background: #00c9a7;
    left: 14px
}

.color-dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin: 0 5px;
}

/*schedule-write-off*/
.schedule-write-off .case {
    position: fixed;
    width: 600px;
    background: #fff;
    top: 0;
    left: calc(10%);
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

.context-menu {
    position: absolute;
    left: 0;
    width: 150px;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 10000;
}

.context-menu .context-item {
    display: inline-block;
    margin: 0;
    padding: 5px;
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
}

.context-menu .context-item.context-danger {
}

.context-menu hr {
    margin: 0;
}

.settings-modal {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
}

.settings-modal .overflow {
    cursor: pointer;
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(33, 30, 47, 0.52);
    transition: all .3s;
    overflow: hidden;
}

.settings-modal .overflow:hover {
    background: rgba(33, 30, 47, 0.6);
}

.settings-modal > .case {
    width: 80%;
    margin: 120px auto;
    max-width: 100%;
    position: relative;
    z-index: 2;
}

.settings-modal.thin > .case {
    width: 50%;
    min-width: 400px;
    max-width: 100%;
}

.settings-modal.wide > .case {
    margin-top: 1rem;
    width: 95%;
}

/*media-photos*/
.media-photos {
}

.media-photos .media-photos-item {
    width: 100%;
    padding-top: 70%;
    margin-bottom: 30px;
    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;
}

.media-photos .media-photos-item .action .icon-danger {
    color: #e83650 !important;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    transition: all .3s ease;
}

.media-photos .media-photos-item .action .icon-danger:hover {
    transform: scale(1.5)
}

.media-photos .media-photos-add {
    width: 100%;
    padding-top: 70%;
    position: relative;
    cursor: pointer;
}

.media-photos .media-photos-add .inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background: #eee;
    color: #555;
    text-align: center;
    line-height: 1.3;
    transition: all .2s ease;
}

.media-photos .media-photos-add .inner:hover {
    background: #14c789;
    color: white;
}

.media-photos .media-photos-add .inner .svg-inline--fa {
    width: 100%;
    font-size: 20px;
}

.icon-danger {
    color: #e25164 !important;
}

.icon-success {
    color: #1dc987 !important;
}

.schedule-write {
}

.schedule-write .inner {
    position: relative;
    padding: 30px;
}

.schedule-write .inner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 5px solid rgba(0, 0, 0, 0.2);
}

.schedule-write .inner .title {
    font-weight: bold;
}

/*avatar*/
.avatar {
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    display: inline-block;
    transition: all .3s ease-in-out;
    margin: 0 8px 0 0;
}

.avatar.avatar-sm {
    width: 45px;
    height: 45px;
}

.avatar.avatar-md {
    width: 65px;
    height: 65px;
}

.avatar.avatar-lg {
    width: 100px;
    height: 100px;
}

.avatar:hover {
    position: relative;
    z-index: 100;
    transform: scale(1.1);
}

.back-link {
    font-size: 10px;
    display: inline-flex;
    position: relative;
    font-weight: 100;
    top: 0;
    left: 0;
    margin: 0 8px 0 0;
    opacity: 0.5;
    transition: all .3s ease;
    text-decoration: none !important;
}

.back-link:hover {
    opacity: 1;
}

.back-link:before {
    content: '⤌ Назад';
    content: '⤌ ' attr(title);
    position: relative;
    color: #fff;
    display: inline-flex;
    width: auto;
    padding: 0 12px;
    height: 22px;
    background: #364b8b;
    border-radius: 25px;
    justify-content: start;
    align-items: center;
    line-height: 1;
}

/*chat*/
.chat {
}

.chat .messages {
    position: relative;
    height: 450px;
    overflow: auto;
}

.chat .messages .message {
}

.chat .messages .message .name {
}

.chat .messages .message .date {
}

.chat .messages .message .text {
}

.archive-total {
    font-size: 13px;
    color: #aaa;
    display: inline-block;
    margin: 0 8px;
}

.main.pressed {
    padding: 0 30px;
}

.main.pressed .sidebar {
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
}

/*checkbox-switch*/
.checkbox-switch {
    position: relative;
}

.checkbox-switch input {
    display: none;
}

.checkbox-switch label {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    margin: 5px 0;
    position: relative;
}

.checkbox-switch label:before,
.checkbox-switch label:after {
    position: relative;
    content: '';
    transition: all .3s ease;
}

.checkbox-switch label:before {
    height: 24px;
    width: 44px;
    background: #ddd;
    border-radius: 15px;
    left: 0;
    margin: 0 15px 0 0;
}

.checkbox-switch label:after {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #fff;
    margin-left: 3px;
    left: 0;
}

.checkbox-switch input:checked ~ label {
    position: relative;
}

.checkbox-switch input:checked ~ label:before {
}

.checkbox-switch input:checked ~ label:after {
    margin-left: 21px;
    background: #5d77ff;
}

.checkbox-switch input:disabled ~ label:after {
    cursor: not-allowed;
    background: #9E9E9E;
}

.dropdown {
}

.dropdown .dropdown-header {
    font-weight: bold;
    text-align: left;
    padding: 0.25rem 1.5rem;
    color: #5d78ff;
    text-transform: uppercase;
    font-size: 10px;
}

.dropdown .dropdown-header:nth-child(n+2) {
    margin-top: 15px;
}

.dropdown .dropdown-menu .text {
    padding: 0.25rem 1.5rem;
}

.dropdown .dropdown-menu {
    color: #646c9a;
    line-height: 1.5;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}

.dropdown .dropdown-item {
    opacity: 0.7;
    padding: 0.5rem 1.5rem;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff !important;
    background-color: #007bff !important;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: inherit;
    opacity: 1;
    outline: none;
    background-color: var(--primary);
    color: #fff;
}

.profile-main,
.profile-contacts {
    border-right: 1px solid #ebedf2;
    min-height: 130px;
}

.profile-main .profile-header {
    color: #464457;
}

.profile-photo {
    position: relative;
    display: inline-block;
    margin-right: 25px;
}

.profile-photo .image {
    border-radius: 50%;
    width: 140px;
    height: 140px;
    background-color: #eee;
    background-size: cover;
    background-position: center;
}

.profile-photo .profile-photo-update {
    position: absolute;
    bottom: 5px;
    right: -1px;
    z-index: 1;
    width: 35px;
    height: 35px;
    background: var(--primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 100%;
}

.profile-main .item {
    font-size: 110%;
    margin-bottom: 15px;
}

.profile-main .item .svg-inline--fa {
    opacity: 0.5;
}

/*chat-window*/
.chat-window {
    position: fixed;
    z-index: 999;
    top: auto;
    left: auto;
    bottom: 15px;
    right: 30px;
    overflow: hidden;
    min-width: 340px;
    height: 500px;
}

.chat-window .chat-textarea {
    resize: none;
}

.chat-window .case > .resize {
    width: 15px;
    height: 15px;

    border-top: 15px solid transparent;
    border-right: 15px solid #24293966;
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
}

.chat-window .back-link:before {
    content: '⤌'
}

.chat-alerts {
    position: fixed;
    z-index: 9999;
    right: 2%;
    bottom: 0;
}

.chat-alert {
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin-bottom: 10px;
    background: #617bff45;
}

.chat-window .chat-header {
    color: #fff;
    background: var(--primary);
    padding-right: 10px
}

.chat-window .chat-header .btn {
    padding: 5px;
}

.chat-window .chat-header .name {
    cursor: all-scroll;
}

.chat-messages {
    max-height: 500px;
    overflow-y: auto;
}

.chat-body .message {
    overflow-wrap: break-word;
}

.chat-window .avatar {
    width: 25px;
    height: 25px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.chat-window .avatar .status {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #fff;
    background-color: #bbb;
}

.chat-window .avatar .counter {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff;
    background: #ddd;
    color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.chat-window .dialogs {
}

.chat-window .dialogs .item {
    cursor: pointer;
    transition: all .3s ease;
    height: 50px;
    overflow: hidden;
}

.chat-window .dialogs .item:hover {
    background-color: rgba(92, 120, 255, 0.07);
}

.alert.alert-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.alert.alert-danger {
    background: rgba(253, 57, 122, 0.48);
    border-color: transparent;
    color: var(--danger);
}

.alert.alert-success {
    background: var(--success);

}

.bg-primary {
    background-color: var(--primary) !important;
}

.alert.alert-solid-warning {
    background: rgba(255, 184, 34, 0.1);
    border-color: transparent;
    color: var(--warning);
}

.alert.alert-solid-danger {
    background: rgba(253, 57, 122, 0.1);
    border-color: transparent;
    color: var(--danger);
}

.alert.alert-solid-success {
    background: rgba(29, 201, 183, 0.1);
    border-color: transparent;
    color: var(--success);
}

.alert.alert-solid-primary {
    background: rgba(93, 120, 255, 0.1);
    border-color: transparent;
    color: var(--primary);
}

.alert.alert-solid-dark {
    background: rgba(100, 92, 161, 0.1);
    border-color: transparent;
    color: #645ca1;
}

/*.swal2-popup */
.swal2-popup {
}

.swal2-popup .swal2-title {
    font-size: 25px !important;
}

.swal2-popup .swal2-content {
    font-size: 16px !important;
}

.swal2-popup .swal2-styled {
    font-weight: 400 !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    border: 1px solid transparent !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 0.9rem !important;
    line-height: 1.6 !important;
    border-radius: 0.25rem !important;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
    outline: none !important;
}

.swal2-popup .swal2-styled.swal2-cancel {
    background: var(--danger) !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background: var(--success) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cursor-pointer {
    pointer-events: all;
    cursor: pointer;
}

.car-placeholder {
    background: url("../images/car_placeholder.png") no-repeat center;
    background-size: contain;
    width: 100px;
    height: 100px;
    max-width: 100%;
}

/*.archive-template {*/
/*    min-width: 1000px;*/
/*}*/

@media (max-width: 760px) {

    .chat-window .case > .resize {
        display: none;
    }

    .chat-window {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0 !important;
        width: 100% !important;
        height: 100% !important;
        font-size: 14px;
    }

    .chat-window .chat-header {
        padding-top: 5px;
        padding-bottom: 8px;
    }

    .chat-window .chat-header .btn {
        font-size: 15px;
        margin-left: 8px;
    }

    .content {
        padding: 0 15px;
    }

    .top-bar {
        left: 0;
        height: 60px;
    }

    .top-services .btn {
        line-height: 2.5;
        padding: 5px 20px;
    }

    .main .sidebar.mobile-hidden {
        transform: translateX(-100%);
    }

    .sidebar-footer {
        width: 100%;
    }

    .main .sidebar {
        right: 0;
        top: 0;
        bottom: 0;
        width: 100% !important;
        transition: all .3s;
    }

    .main .main-container {
        margin: 0 !important;
    }

    .media-browser-item-modal {
        padding: 0;
    }

    .media-browser-item-modal .modal-container {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 0;
    }

    .media-browser-item-modal .modal-container .settings {
        padding: 15px;
    }

}

@media print{

    body{
        color:#000;
        background:#fff;
    }
    .main .main-container{
        position:relative;
        left:0;
        right:0;
        width:100%;
        margin-left:0;
        padding:0;
    }
    .archive-template .filters{
        display:none;
    }
    .content{
        padding:0;
    }
    .sidebar,.top-bar{
        display:none;
    }
}